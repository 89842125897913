<template>
  <BasePage
    padding
    title="Schede attive"
    subtitle="elenco schede attive nel periodo selezionato"
    :breadcrumb="$route.matched"
  >
    <!-- table -->
    <MaintenancePendingTableList
      :data="data"
      :loading="loading"
      :pagination.sync="pagination"
      :filters="filters"
      @filter="(newFilters) => (filters = newFilters)"
      @row-click="(e, row) => show(row.id)"
      @request="onRequest"
    />

    <q-toolbar class="wrapped q-pt-lg q-pb-md">
      Manutenzioni da completare
    </q-toolbar>

    <!-- others -->
    <MaintenancePendingTableList
      :data="dataOthers"
      @row-click="(e, row) => show(row.id)"
    />
  </BasePage>
</template>

<script>
import Maintenances from '../apis/maintenances.js'
import MaintenancePendingTableList from '../components/maintenances/MaintenancePendingTableList.vue'
import useApiCrudTable from '../hooks/apiCrudTable.js'
import { Cookies, date } from 'quasar'
import { reactive, toRefs } from '@vue/composition-api'

export default {
  name: 'PendingJobs',

  components: {
    MaintenancePendingTableList,
  },

  setup(_, ctx) {
    //default last week
    const to_pending =
      Cookies.get('to_pending') || date.formatDate(new Date(), 'YYYY-MM-DD')
    const from_pending =
      Cookies.get('from_pending') ||
      date.formatDate(
        new Date().setDate(new Date().getDate() - 7),
        'YYYY-MM-DD'
      )

    const sort = 'status'
    const dir = 'asc'
    const status = [1, 2, 3, 9]

    const stateOthers = reactive({
      dataOthers: [],
    })

    const { loading, onRequest, state, show } = useApiCrudTable({
      api: Maintenances,
      ctx,
      routes: {
        index: 'pending-jobs',
        show: 'maintenanceShow',
      },
      filters: {
        sort,
        dir,
        from_pending,
        to_pending,
      },
      events: {
        index(response) {
          const exclude_id = response.data.map((item) => item.id)

          Cookies.set('from_pending', state.filters.from_pending)
          Cookies.set('to_pending', state.filters.to_pending)

          const yesterday = new Date()
          yesterday.setDate(yesterday.getDate() - 1)
          const to_registered = date.formatDate(yesterday, 'YYYY-MM-DD')

          //others
          Maintenances.index({
            status,
            sort,
            dir,
            exclude_id,
            to_registered,
          }).then(({ data }) => {
            stateOthers.dataOthers = data
          })

          return response
        },
      },
    })

    return {
      loading,
      onRequest,
      show,
      ...toRefs(state),
      ...toRefs(stateOthers),
    }
  },
}
</script>
