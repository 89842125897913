<template>
  <q-table
    class="wrapped"
    flat
    square
    binary-state-sort
    v-bind="$attrs"
    v-on="$listeners"
    :columns="columns"
  >
    <template v-if="!!filters" #top>
      <MaintenancePendingFilters
        :value="filters"
        @input="(filters) => $emit('filter', filters)"
      />
    </template>

    <template v-slot:body="props">
      <q-tr
        @click="$emit('row-click', $event, props.row)"
        class="cursor-pointer"
      >
        <q-td v-for="col in props.cols" :key="col.name" :props="props">
          <StatusBadge v-if="col.name == 'status'" :value="col.value.id" />

          <div v-else-if="col.name == 'planning'">
            <q-icon
              class="q-mr-sm"
              size="md"
              :name="planningIcon(col.value)"
              :color="planningColor(col.value)"
            />
            <q-tooltip anchor="top middle">Lavori programmati</q-tooltip>
            {{ col.value.tasks_planned }} /
            <strong>{{ col.value.tasks_count }}</strong>
          </div>

          <template v-else>
            {{ col.value }}
          </template>
        </q-td>
      </q-tr>
    </template>
  </q-table>
</template>

<script>
import MaintenancePendingFilters from './MaintenancePendingFilters.vue'
import StatusBadge from './StatusBadge.vue'
import { date } from 'quasar'
import { useActions, useGetters } from '../../hooks/store.js'
import { computed } from '@vue/composition-api'

export default {
  name: 'MaintenancePendingTableList',
  inheritAttrs: false,

  components: {
    MaintenancePendingFilters,
    StatusBadge,
  },

  props: {
    filters: {
      type: Object,
    },
  },

  setup() {
    const { getKind } = useGetters('maintenance', ['getKind'])
    const { userCan } = useGetters('auth', ['userCan'])

    //load status
    const { loadStatus } = useActions('maintenance', ['loadStatus'])
    loadStatus()

    //planning methods
    function planningColor(value) {
      if (value.tasks_planned == value.tasks_count) return 'positive'
      if (value.tasks_planned == 0) return 'negative'
      return 'warning'
    }
    function planningIcon(value) {
      if (value.tasks_planned == value.tasks_count)
        return 'mdi-tooltip-check-outline'
      if (value.tasks_planned == 0) return 'mdi-tooltip-outline'
      return 'mdi-tooltip-minus-outline'
    }

    const columns = computed(() => {
      const columns = [
        {
          name: 'registration_date',
          label: 'Data ingresso',
          align: 'left',
          field: 'registration_date',
          sortable: true,
          format: (value) => date.formatDate(value, 'DD/MM/YYYY'),
          style: 'width:80px',
        },
        {
          name: 'delivery_date',
          label: 'Data uscita',
          align: 'left',
          field: 'delivery_date',
          sortable: true,
          format: (value) => date.formatDate(value, 'DD/MM/YYYY'),
          style: 'width:80px',
        },
        {
          name: 'customer',
          label: 'Cliente',
          align: 'left',
          field: (row) => row.customer.fullname,
          style: 'width:120px',
        },
        {
          name: 'vehicle',
          label: 'Veicolo',
          align: 'left',
          field: (row) =>
            row.vehicle
              ? `${row.vehicle.model.brand.name} - ${row.vehicle.model.name} ${row.vehicle.model.year}`
              : '',
        },
        {
          name: 'kind',
          label: 'Genere',
          align: 'left',
          field: (row) => getKind(row.kind).value.label,
        },
        {
          name: 'status',
          label: 'Stato',
          field: 'status',
          align: 'left',
          sortable: true,
          style: 'width:180px',
        },
      ]

      if (userCan('tasks_manage').value) {
        columns.push({
          name: 'planning',
          label: 'Programmazione',
          field: (row) => {
            const { tasks_planned, tasks_count } = row
            return { tasks_planned, tasks_count }
          },
          align: 'center',
          style: 'width:80px',
        })
      }

      return columns
    })

    return {
      planningColor,
      planningIcon,
      columns,
    }
  },
}
</script>
