<template>

  <q-toolbar class="q-px-none">

    <q-icon
      name="mdi-filter-variant"
      class="q-mr-sm" />

    <div class="flex-md full-width">

      <BaseDatePicker
        style="width:160px"
        class="q-pb-none q-ml-sm"
        label="Dal"
        dense
        clearable
        clear-icon="close"
        :value="value.from_pending"
        @input="from_pending => setFilter({from_pending})" />

      <BaseDatePicker
        style="width:160px"
        class="q-pb-none q-ml-sm"
        label="Al"
        dense
        clearable
        clear-icon="close"
        :value="value.to_pending"
        @input="to_pending => setFilter({to_pending})" />

      <BaseBtn
        type="button"
        label="Scarica la tabella"
        icon="mdi-file-pdf"
        class="q-ml-auto"
        color="dark"
        :disabled="downloading"
        :loading="downloading"
        @click="download" />

    </div>

  </q-toolbar>

</template>

<script>
import Maintenances from '../../apis/maintenances.js'
import useNotify from '../../hooks/notify.js'
import useToggler from '../../hooks/toggler.js'

export default{
  name: 'MaintenancePendingFilters',

  props: {
    value: {
      type: Object,
    },

  },

  setup(props, {emit}){
    const { ongoing, update } = useNotify()
    const { toggler : downloading, on: startDownload, off: stopDownload } = useToggler()

    function setFilter( value ){
      emit( 'input', { ...props.value, ...value } )
    }

    function download()
    {
      let notify = ongoing( 'Generazione in corso' )

      startDownload()

      //download
      Maintenances
        .downloadPendings( props.value )
        .then( () => {
          update( notify )
        })
        .catch( () => {
          update( notify, 'Errore durante l\'operazione', { color: 'negative', icon : 'mdi-alert-outline', } )
        })
        .finally( () => stopDownload() )
    }


    return {
      download,
      downloading,
      setFilter,
    }
  },

}
</script>

